import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'

import React, { useState } from 'react';


//RUNNING BUGS
//MOBILE RTEAM PAGE
//CARD SAME SIZE
//ON CLICK ACTIVE 
//NAV BAR 
function Home() {

  const [currentPage, setCurrentPage] = useState('Home');

  return (<div>
    <div className='responsiveDiv'>

     
      <h1 className='mt-[1rem] font-bold'>Lab for Interaction & Immersion</h1>
      <Carousel className='w-full' interval={3000}>
      <Carousel.Item>
      <img
              alt=""
              src={homeCarousel1}

              className="d-block w-100 m-auto fit"
            />{' '}
       
      </Carousel.Item>
      <Carousel.Item>
      <img
              alt=""
              src={homeCarousel2}
              
             className="d-block w-100 m-auto fit"
            />{' '}
      
      </Carousel.Item>
      <Carousel.Item>
      <img
              alt=""
              src={homeCarousel3}
       
              className="d-block w-100 m-auto fit"
            />{' '}
        
      </Carousel.Item>
      <Carousel.Item>
      <img
              alt=""
              src={homeCarousel4}
       
              className="d-block w-100 m-auto"
            />{' '}
        
      </Carousel.Item>
      
    </Carousel>
{/*https://www.youtube.com/embed/b-TGwnAg56g?si=baB5WJjk_MnG1_1P*/}
    <p className='text-left mt-[1rem]'>
    The Lab for Interaction and Immersion explores technologies and artistic concepts for human-machine and human-human interaction in music and sound art, with a focus on spatial audio and immersive experiences. 
    </p>

    <figure class="video">
    <iframe src="https://www.youtube.com/embed/b-TGwnAg56g?si=baB5WJjk_MnG1_1P" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</figure>




    
      </div>
      
    </div>
  );
}

export default Home;
