import logo from './logo.svg';
import l42iLogo from './media/l42i_white.png'
import gtLogo from './media/logo.png'
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from './media/2024_spring_team_crop.jpg'
import homeCarousel2 from './media/cube4.png'
import homeCarousel3 from './media/rich_speaker_setup.png'
import homeCarousel4 from './media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from './media/Henrik_von_Coler.jpg'
import Home from './components/Home'

import React, { useState } from 'react';
import ThreeDBox from './components/3DBox';
import ARCube from './components/ARCube';
import People from './components/People';
import Scz from './components/Scz';
import Anarx from './components/Anarx';
import Contest from './components/Contest'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function App() {

  const [currentPage, setCurrentPage] = useState('Home');

  return (
   <div className='App'>

      

      <Navbar expand="xl" className="customBg sticky-top">
      <Container>

        <Navbar.Brand >
          <a href="/">
        <button><img
              alt=""
              
              src={gtLogo}
              width="200"
              height="200"
              className="d-inline-block align-top"
            />{' '}
            </button>
            </a>
           
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="/projects/3dbox">3DBox</NavDropdown.Item>
              <NavDropdown.Item href="/projects/arcube">
                ARCube
              </NavDropdown.Item>
              <NavDropdown.Item href='/projects/schizosymphony'>
              Schizosymphony
              </NavDropdown.Item>
              <NavDropdown.Item href="/projects/anarx">
              AnarX
              </NavDropdown.Item>
            
              
            </NavDropdown>
            <Nav.Link href="/people">People</Nav.Link>
            <Nav.Link href="/contest">Contest</Nav.Link>
            
        
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>



    <Router>
     
     
     <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/projects/3dbox" element={<ThreeDBox />} />
       <Route path="/projects/arcube" element={<ARCube />} />
       <Route path="/projects/schizosymphony" element={<Scz />} />
       <Route path="/projects/anarx" element={<Anarx />} />
       <Route path="/contest" element={<Contest />} />

       <Route path="/people" element={<People />} />
       
       
     </Routes>
    </Router>

      <div className="myFooter">
    
        <p>

       {/* <a href="mailto:l42i@music.gatech.edu" className='italic'>l42i@music.gatech.edu</a>*/}
 204 Couch Bldg (Music)<br></br>840 McMillan St NW<br></br>Atlanta, GA 30332</p>
      </div>
   
      
    </div>
   
  );
}

export default App;
