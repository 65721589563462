import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Scz1 from '../media/schizo2-icn_ts-1.png'
import nas from '../media/networkaudio.jpg'
import nasStudents from '../media/nastudents.jpeg'

import React, { useState } from 'react';

function Anarx() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] mb-[1rem] font-bold'>AnarX</h1>
    

    <figure class="video">
        <iframe src="https://www.youtube.com/embed/170HlC02vOA?si=qceYPZ-xfO44RN-W" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </figure>

    

    
    
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>

    Network systems can be used to transmit audio signals in local or remote scenarios. This allows rehearsals and performances across long distances with large numbers of participants. But beyond this, however, network technologies can be an integral part of the creative process.
<br></br>
<br></br>
AnarX, the Atlanta Network Audio Reasearch Experience, explores the use of local and global networks for the composition and performance of music and sound art.
<br></br>
<br></br>
<img src={nas} className='w-full m-auto'></img>
<br></br>

<h4 className='text-left'>CURE Project</h4>
In Fall 2024, the project is funded as a CURE class for undergraduate research:
<br></br>
<br></br>
We explore the artistic potential of network technologies in on-site and distributed configurations. For this we rely on JackTrip and a dedicated cluster of Raspberry Pi 5 microcomputers, with touch screens and dedicated audio interfaces + speakers.

<br></br>
<br></br>
<a href="https://ctl.gatech.edu/transformative-teaching-and-learning-faculty-initiatives" target='_blank'>https://ctl.gatech.edu/transformative-teaching-and-learning-faculty-initiatives</a>
 </p>

<h4 className='text-left'>Spring 24</h4>
<p>In Spring 2024 we conducted an initial test with a group of four undergraduate students for their capstone project. </p>
    
   
<img src={nasStudents} className='w-full m-auto'></img>
    <br></br>
  </div>

  </div>
  );
}

export default Anarx;