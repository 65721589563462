import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Evan from '../media/evan_murray.jpg'
import Orlando from '../media/Orlando_Kenny.jpeg'
import Xinni from '../media/XinniLi.jpeg'
import Luhee from '../media/luhee.png'



import React, { useState } from 'react';

//test

function Contest() {

  return (
    <div className='responsiveDiv'>
    

<h1 className='mt-[1rem] font-bold'>GT3D Contest</h1>
<h3>2024</h3>
<img src={homeCarousel3} className='w-[60%] m-auto'></img>
<div className='text-left'>

<p className='text-left mt-[1rem] font-bold'>
L42i is excited to announce the 1st Georgia Tech 3D Student Composition Contest.
<br></br>
</p>
<p>
With the 3DBox in the Rich Computer Center up and running, we are now looking for fresh content created in the School of Music. The contest will run through this Fall semester and is open to all SoM students. We welcome any style and genre, if it is fixed media (audio only), in the Ambisonics format, with a length of 5-15 minutes. 
<br></br>
<br></br>
<li>Students work with their own computer, using the tools of their choice.</li>
<li>Contestants can book the 3DBox weekdays after 4PM and on weekends.</li>
<li>Compositions will be submitted at the end of the 2024 fall semester. </li>
<li>A SoM jury will pick the winners, who receive prizes totaling $1000. 
</li>

<p className='text-center mt-[1rem]'><span className='font-bold'>Judges</span>
  <br></br>

  <a href="https://music.gatech.edu/people/alexandria-smith" target="_blank">Alexandria Smith</a> <br></br>
<a href="https://music.gatech.edu/brittney-boykin" target="_blank">Brittney Boykin</a><br></br>
<a href="https://music.gatech.edu/people/chaowen-ting" target="_blank">Chaowen Ting</a> <br></br>
<a href="https://music.gatech.edu/people/jeremy-muller" target="_blank">Jeremy Muller </a><br></br>
<a href="https://music.gatech.edu/people/henrik-von-coler" target="_blank">Henrik von Coler</a><br></br>
</p>

<p className="font-bold italic text-center mt-[1rem]">You must attend a 3DBox introduction session to join the contest:</p>
<div className='btnContainer'>
<Button variant="secondary" href="https://doodle.com/sign-up-sheet/participate/8ff81804-2fa3-4250-bf9d-21d38e246611/select" target='_blank' className='btn'>Sign Up for the Introduction Session</Button>

</div>

<br></br>
<br></br>
<div className='btnContainer'>
<h3>Questions?</h3>
</div>
<div className='btnContainer'>
<p className='questions'>If you can't find the information you were looking for, we'll get you to the right place.</p>
</div>
<div className='btnContainer'>
<Button variant="secondary" href="mailto:hshin336@gatech.edu" target='_blank' className='btn'>Contact Us</Button>

</div>
</p>


        
   

    
</div>

</div>
  );
}

export default Contest;