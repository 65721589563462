import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Scz1 from '../media/schizo2-icn_ts-1.png'

import React, { useState } from 'react';

function Scz() {

  return (
    <div className='responsiveDiv'>

    <h1 className='mt-[1rem] mb-[1rem] font-bold'>Schizosymphony</h1>

    <figure class="video">
        <iframe src="https://www.youtube.com/embed/yph2hXzY9Dc?si=7rM7J-xf5o00A_aV" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </figure>
   
    
    <div className='text-left'>
    <p className='text-left mt-[1rem]'>

    Schizosymphony is an artistic composition that intricately weaves urban soundscapes into a unified auditory experience. This piece sonifies a narrative soundscape by assigning distinct audio elements to 53 Intrinsic Connectivity Networks (ICNs), organized according to the primary categories mapped to 7 Functional Domains (FDs). This method creates a cohesive urban landscape, capturing moments from daily life typically encountered by a patient and merging them into a singular sound scene. 
    </p>

    <img src={Scz1} className='w-full m-auto'></img>
    <h4 className='text-left mt-[1rem]'>Data and Data Preprocessing</h4>
    <p>The functional magnetic resonance imaging (fMRI) data from the Function Biomedical Informatics Research Network (FBIRN) dataset were processed with the NeuroMark pipeline. Subject-specific functional components and corresponding time courses were then estimated using the spatially constrained independent component analysis (ICA). The spatial components are the spatial maps of intrinsic connectivity networks (ICNs). These 53 ICNs can be grouped to 7 functional domains (FDs) according to their functional properties, including the subcortical (SC), auditory (AU), sensorimotor (SM), visual (VI), cognitive control (CC), default mode (DM) and cerebellar (CB) domains. 

<br></br>

<br></br>
<h4>Related Publication</h4>
       <a target="_blank" className='italic' href="https://www.researchgate.net/publication/382149438_SCHIZOSYMPHONY_FROM_SCHIZOPHRENIA_BRAINWAVES_TO_NARRATIVE_SOUNDSCAPES?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6ImhvbWUiLCJwYWdlIjoic2VhcmNoIiwicG9zaXRpb24iOiJwYWdlSGVhZGVyIn19">
Shin, Hyunkyung & Li, Xinhui & Fu, Zening & von Coler, Henrik. (2024). SCHIZOSYMPHONY: FROM SCHIZOPHRENIA BRAINWAVES TO NARRATIVE SOUNDSCAPES. International Conference on Auditory Display (ICAD 2024)
  </a>
       <br>
       </br>
       <br></br>

    </p>
  
    
    
  </div>

  </div>
  );
}

export default Scz;